import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'lading_bills',
    prefix: 'warehouse.lading_bills_order',
    prefix_state: 'warehouse_lading_bills_order',
  },
  {
    default_query: {
      include: "container,pivotBoxes,boxes,customer",
      sort: "-created_at",
    },
    detail_query: {
      include: "container,boxes,compensations,pivotBoxes,customer"
    },
    default_detail: {
      customer: {},
    }
  }
).store()

export default resource

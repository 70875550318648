export default [
  {
    _name: "TSidebarNavItem",
    name: "Dashboard",
    to: "/sale/dashboard",
    icon: "cil-speedometer",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Orders",
  },
  {
    _name: "TSidebarNavItem",
    name: "Retail",
    to: "/sale/orders/retail",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Wholesale",
    to: "/sale/orders/wholesale",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Shipment",
    to: "/sale/orders/shipment",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Payment Order",
    to: "/sale/orders/payment",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavItem",
    name: "Auction Order",
    to: "/sale/orders/auction",
    icon: "cil-library-books",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Statistics",
  },
  {
    _name: "TSidebarNavItem",
    name: "Goods inventory",
    to: "/sale/statistics/goods-inventory",
    icon: "cil-library-bookmark",
  },
  {
    _name: "TSidebarNavItem",
    name: 'Customer debt',
    to: "/sale/statistics/customer-debt",
    icon: "cil-money",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Management",
  },
  {
    _name: "TSidebarNavItem",
    name: "Contract",
    to: "/sale/contracts/contracts",
    icon: "cil-library-bookmark",
  },
  {
    _name: "TSidebarNavItem",
    name: "Auction logs",
    to: "/sale/auction-logs",
    icon: "cil-note",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tracking",
    to: "/sale/tracking",
    icon: "cil-cursor",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Goods",
  },
  {
    _name: "TSidebarNavItem",
    name: "Product",
    to: "/sale/goods/products",
    icon: "cil-chart-table",
  },
  {
    _name: "TSidebarNavItem",
    name: "Category",
    to: "/sale/goods/categories",
    icon: "cil-view-list",
  },
  {
    _name: "TSidebarNavItem",
    name: "Origin",
    to: "/sale/goods/origins",
    icon: "cil-toll",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Support",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support",
    to: "/sale/supports",
    icon: "cil-comment-bubble-question",
  },
];

import order_retail from './order/order_retail'
import order_wholesale from './order/order_wholesale'
import order_shipment from './order/order_shipment'
import retail_statuses from './order/retail_statuses'
import wholesale_statuses from './order/wholesale_statuses'
import shipment_statuses from './order/shipment_statuses'
import order_items_wholesale from './order/order_items_wholesale'
import order_items_auction from './order/order_items_auction'
import order_items_payment from './order/order_items_payment'
import order_auction from './order/order_auction'
import order_payment from './order/order_payment'
import payment_statuses from './order/payment_statuses'
import auction_statuses from './order/auction_statuses'
import order_available_for_contract from './order/order_available_for_contract'
import sale_orders from './order/sale_orders'
import lading_bills_order from './warehouse/lading_bills_order'
import order_auction_logs from './order/order_auction_logs'

export default {
  order_retail,
  order_wholesale,
  order_shipment,
  retail_statuses,
  wholesale_statuses,
  shipment_statuses,
  order_items_wholesale,
  order_items_payment,
  order_items_auction,
  order_auction,
  order_payment,
  payment_statuses,
  auction_statuses,
  order_available_for_contract,
  sale_orders,
  lading_bills_order,
  order_auction_logs,
}

import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_retail'
  },
  {
    default_query: {
      include: "itemsCount,customer",
      append: "first_item",
      sort: "-created_at",
    },
    detail_query: {
      append: 'related_trackings,owning_boxes_from_origin',
      include: 'customer',
    },
    default_detail: {
      id: '',
      status_id: '',
      customer_id: '',
      object: {
        name: ''
      },
      related_trackings: [],
      owning_boxes_from_origin: [],
    }
  },
).store()

export default resource

import Resource from '@/core/store/Resource';


const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
    prefix: 'order.sale_orders',
    prefix_state: 'order_sale_orders',
  },
  {
    default_query: {
      include: 'type,status,customer',
      append: 'first_item,related_trackings',
      sort: '-created_at'
    }
  },
).store()

export default resource

import Resource from "@/core/store/Resource";

const resource = new Resource(
  {
    service: "order",
    resource: "order_wholesale"
  },
  {
    default_query: {
      append: "first_item",
      sort: "-created_at",
      include: "customer"
    },
    detail_query: {
      append: "related_trackings,owning_boxes_from_origin",
      include: "contract,customer"
    },
    default_detail: {
      id: null,
      status_id: null,
      customer_id: '',
      related_trackings: [],
      owning_boxes_from_origin: [],
      contract: {
        status_id: ''
      },
    }
  },
).store();

export default resource;

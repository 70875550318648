import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
    prefix: 'order.order_available_for_contract',
    prefix_state: 'order_available_for_contract',
  },
  {
    default_query: {
      include: "trackings",
      sort: "-updated_at",
      "filter[available_for_contract]": "1"
    },
  },
).store()

export default resource

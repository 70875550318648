import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'order_id', store: 'order.order_wholesale' }),
]

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_items',
    prefix: 'order.order_items_wholesale',
    prefix_state: 'order_items_wholesale',
  },
  {
    default_query: {
      append: "currency_id",
      include: "purchaseItems,distributions.purchaseItem,product",
    },
  },
  touchs
).store()

export default resource

import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_auction_logs'
  },
  {
    default_query: {
      include: "user",
      sort: "-id"
    },
    detail_query: {},
  },
).store()

export default resource

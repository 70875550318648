import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'id', store: 'order.order_auction' }),
]

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_auction'
  },
  {
    default_query: {
      append: "first_item",
      sort: "-created_at",
      include: "customer"
    },
    detail_query: {
      append: "related_trackings,owning_boxes_from_origin",
      include: "contract,customer",
    },
    default_detail: {
      id: '',
      status_id: '',
      customer_id: '',
      related_trackings: [],
      owning_boxes_from_origin: [],
      purchased: true,
      contract: {
        status_id: '',
      },
    }
  },
  touchs
).store()

export default resource

import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_payment'
  },
  {
    default_query: {
      append: "first_item",
      include: "itemsCount,customer",
      sort: "-created_at",
    },
    detail_query: {
      append: "related_trackings,owning_boxes_from_origin",
      include: "attachments,contract,customer",
    },
    default_detail: {
      id: '',
      status_id: '',
      customer_id: '',
      related_trackings: [],
      owning_boxes_from_origin: [],
      contract: {
        status_id: '',
      },
    }
  },
).store()

export default resource
